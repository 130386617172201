/**
 * Get the menu-data JSON.
 * Tries to access previously cached menu-data from the local storage. Failure to do so results in a fetch.
 *
 * @returns {Promise<[]>}
 */
export default async function fetchMenuData() {
  let data, menuString

  try {
    menuString = localStorage.getItem('menuData')
  } catch (e) {
    // failure is anticipated, nothing to do here
  }

  if (process.browser && menuString && menuString !== 'undefined') {
    data = JSON.parse(menuString)
  } else {
    const response = await fetchMenuFromApi()

    data = response.menu
  }

  return data
}

async function fetchMenuFromApi() {
  const response = await fetch(
    `${process.env.MAKAIRA_API_URL}/enterprise/menu`,
    {
      method: 'GET',
      headers: {
        'X-Makaira-Instance': process.env.MAKAIRA_API_INSTANCE,
      },
    }
  )

  if (response.status !== 200) {
    const { status, statusText } = response
    const errorBody = await response.json()

    const error = new Error()
    error.code = status
    error.message = statusText
    error.cause = errorBody.message
    error.stack = 'fetchMenuData()'

    console.error(error)

    return {
      menu: [],
    }
  }

  return response.json()
}
